import * as React from "react"
import "@styles/404.scss"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

const NotFoundPage = () => {
  return (
    <>
      <div className="page-not-found pnf-wrapper">
        <Row>
          <Col xs={12} sm={12} md={6}>
            <h1>404</h1>
          </Col>
          <Col xs={12} sm={12} md={6} className="text-block">
            <h2>Page not found</h2>
            <p>Oops! You’ve taken a wrong turn somewhere along the way.</p>
            <Link to={"/"} className="cta pnf-cta">
              {" "}
              back to home
            </Link>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default NotFoundPage
